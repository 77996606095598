import React from "react";
import SEO from "components/seo";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import config from "../../../config/site-config";
import FontPreload from "components/font-preload";
import facebookShare from "images/shareImages/frontpage/share-facebook.jpg";

const ArticlePage = ({ pageContext, data }) => {
    const { currentArticlePage, frontpageData } = data;
    const { locale } = pageContext;
    const seo = { lang: locale };
    const articleHasSeoProps = currentArticlePage.seo;

    seo.title = articleHasSeoProps
        ? currentArticlePage.seo.title
        : frontpageData.seo.title;

    seo.description = articleHasSeoProps
        ? currentArticlePage.seo.description
        : frontpageData.seo.description;

    if (
        articleHasSeoProps &&
        currentArticlePage.seo.image &&
        currentArticlePage.seo.image.url
    ) {
        seo.shareImageUrl = currentArticlePage.seo.image.url;
    } else {
        seo.shareImageUrl = facebookShare;
    }

    return (
        <>
            <SEO lang={locale} {...seo} />
            <Helmet>
                {data.currentSlug.edges.map((currentSlug, key) => {
                    return (
                        currentSlug.node.slug && (
                            <link
                                key={key}
                                rel="alternate"
                                href={`${config.siteUrl}/${currentSlug.node.locale}/${currentSlug.node.slug}`}
                                hreflang={
                                    currentSlug.node.locale === "en"
                                        ? "x-default"
                                        : currentSlug.node.locale
                                }
                            />
                        )
                    );
                })}
            </Helmet>
            <FontPreload />
        </>
    );
};

export default ArticlePage;

export const query = graphql`
    query articlepageData($locale: String!, $originalId: String!) {
        currentArticlePage: datoCmsArticle(
            locale: { eq: $locale }
            originalId: { eq: $originalId }
        ) {
            hideFromNavigation
            id: originalId
            title
            locale
            menuTitle
            seo {
                title
                description
                twitterCard
                image {
                    url
                }
            }
            sections {
                title
                hideAssetsMetaData
                description
                link {
                    menuTitle
                    slug
                }
                moreDetailsLink {
                    menuTitle
                    slug
                }
                moreDetailsLinkLabel
                buttonLinks {
                    title
                    slug
                }
                linkToNonArticle
                linkToNonArticleSlug
                assets {
                    alt
                    filename
                    format
                    title
                    url
                    mimeType
                    basename
                    path
                    size
                    video {
                        thumbnailUrl(format: jpg)
                        mp4Url(res: medium)
                    }
                    fluid(
                        maxWidth: 1200
                        imgixParams: { auto: "compress", fm: "jpg" }
                    ) {
                        srcSet
                        src
                        sizes
                        base64
                        aspectRatio
                        width
                        height
                    }
                }
                carousel {
                    ... on DatoCmsCarouselImage {
                        originalId
                        image {
                            title
                            alt
                            fixed(width: 1200) {
                                src
                                width
                            }
                        }
                    }
                    ... on DatoCmsCarouselVideo {
                        originalId
                        video {
                            title
                            video {
                                thumbnailUrl(format: jpg)
                                mp4Url(exactRes: medium)
                            }
                        }
                    }
                    ... on DatoCmsCarouselVideoId {
                        originalId
                        cloudinaryId
                        caption
                    }
                }
            }
            carousel {
                ... on DatoCmsCarouselImage {
                    originalId
                    image {
                        alt
                        title
                        fixed(width: 1200) {
                            src
                            width
                        }
                    }
                }
                ... on DatoCmsCarouselVideo {
                    originalId
                    video {
                        title
                        video {
                            thumbnailUrl(format: jpg)
                            mp4Url(exactRes: medium)
                        }
                    }
                }
                ... on DatoCmsCarouselVideoId {
                    originalId
                    cloudinaryId
                    caption
                }
            }
            slug
            body
        }
        articlePages: allDatoCmsArticle(
            filter: { title: { ne: null }, locale: { eq: $locale } }
            sort: { fields: position }
        ) {
            edges {
                node {
                    slug
                    menuTitle
                    hideFromNavigation
                }
            }
        }
        serviceMenuLinks: allDatoCmsServiceMenu(
            filter: {
                locale: { eq: $locale }
                internalLink: { slug: { ne: null } }
            }
            sort: { fields: position }
        ) {
            edges {
                node {
                    menuTitle
                    locale
                    externalLink
                    linkType
                    internalLink {
                        slug
                    }
                }
            }
        }
        frontpageData: datoCmsFrontpage(locale: { eq: $locale }) {
            id: originalId
            seo {
                title
                description
            }
            mainCtaLabel
            logoText
            takeActionLink {
                menuTitle
                slug
            }
            articlePageLink {
                menuTitle
                slug
            }
        }
        nativeLanguages: allDatoCmsLanguage(
            filter: { locale: { eq: $locale } }
        ) {
            edges {
                node {
                    id
                    code
                    nativeName
                }
            }
        }
        currentSlug: allDatoCmsArticle(
            filter: { originalId: { eq: $originalId } }
        ) {
            edges {
                node {
                    originalId
                    slug
                    locale
                }
            }
        }
        globalTexts: datoCmsGlobalText(locale: { eq: $locale }) {
            languageSelectorLabel
            mapMenuLabel
            streamingMenuLabel
            toolkitsMenuLabel
        }
        consentLabels: datoCmsParentalConsentPage(locale: { eq: $locale }) {
            acceptTermsMessage
            userEmailLabel
            submitLabel
            checkboxText
            approvedLabel
        }
        resetPasswordLabels: datoCmsResetPasswordPage(locale: { eq: $locale }) {
            resetPasswordButtonLabel
        }
        cookiePolicyLabel: datoCmsGlobalText(locale: { eq: $locale }) {
            cookiePolicyLabel
        }
    }
`;
